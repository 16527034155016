<template>
  <div class="barCode">
    <mt-header :title="$t('barCodePageTitle')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="box">
        <div class="title">{{ $t('barCodeTitle') }}</div>
        <div class="val">
          <div class="amount">
            <span>₱</span>
            {{ amount }}
          </div>
          <div class="text">{{ $t('barCodeText') }}</div>
          <div class="referenceNo">{{ referenceNo || '- - - - - - - - - -' }}</div>
          <template v-if="barCode">
            <div class="tips">{{ $t('barCodeTips') }}</div>
            <vue-barcode class="vue-barcode" v-model="barCode" format="CODE128" height="60" background="#f9f9f9" />
            <div class="refresh" @click="getBarCode('Y')">{{ $t('clickRefresh') }}</div>
          </template>
        </div>
      </div>
      <div class="bottom">
        <mt-button size="large" @click="submit">{{
          $t("back")
        }}</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import callPhone from '@/components/callPhone.vue'
import VueBarcode from 'vue-barcode'
import { barCode } from '../../utils/api'
export default {
  name: 'BarCode',
  components: {
    callPhone,
    VueBarcode,
  },
  data () {
    return {
      barCode: '',
    }
  },
  computed: {
    ...mapState(["user"]),
    amount() {
      return this.$route.query.amount || 0
    },
    referenceNo() {
      return this.$route.query.referenceNo || 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.getBarCode('N')
    },
    async getBarCode(refresh) {
      if (!this.user.applyId) return
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      const formData = new FormData()
      formData.append('applyId', this.user.applyId)
      formData.append('refresh', refresh)
      formData.append('amount', this.amount)
      await this.$axios({
        method: 'post',
        url: barCode,
        data: formData
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.barCode = e.body
            // this.barCode = "2020-2020-2020"
          }
        })
        .catch(() => {})
      this.$indicator.close();
      this.$NProgress.done()
    },
    submit() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.barCode {
  background: #1B89F9;
  min-height: 100vh;
  .content {
    .box {
      overflow: hidden;
      margin: 40px 20px;
      .title {
        background-color: #F1F1F1;
        border-radius: 10px 10px 0px 0px;
        padding: 20px 10px;
        border: 1px solid #EEEEEE;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
      .val {
        border-radius: 0px 0px 10px 10px;
        border: 1px solid #EEEEEE;
        border-top: 0px;
        position: relative;
        background: white;
        .amount {
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          padding-top: 30px;
        }
        .text {
          color: #61afe6;
          text-align: center;
          font-size: 12px;
        }
        .referenceNo {
          margin: 10px 20px;
          border-radius: 5px;
          background: #F1EFF6;
          padding: 5px;
          text-align: center;
          font-size: 25px;
          font-weight: bold;
        }
        .vue-barcode {
          text-align: center;
          /deep/ .vue-barcode-element {
            width: 100%;
          }
        }
        .tips {
          font-size: 12px;
          padding: 20px 5px 0px 5px;
          font-weight: initial;
          color: #848484;
          text-align: center;
        }
        .refresh {
          color: #a71d5d;
          text-align: center;
          margin-bottom: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          top: -10px;
          left: -10px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: #1B89F9;
          border: 1px solid #EEEEEE;
        }
        &::after {
          content: "";
          position: absolute;
          top: -10px;
          right: -10px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: #1B89F9;
          border: 1px solid #EEEEEE;
        }
      }
    }
    .bottom {
      padding: 20px 20px;
    }
  }
}
</style>